import React, { Component } from 'react'
import styled, { keyframes }  from 'styled-components'
import Link from 'gatsby-link'
import sevenhugsProductData from '../../sevenhugsProductData.json'
import HeroImage from '../components/heroImage'
import LargeImage from '../components/largeImage'
import LargeImage2 from '../components/largeImage'
import HeroCaseStudy from '../components/heroCaseStudy'
import TextBlockCentered from '../components/textBlockCentered'
import TextBlockCenteredBullet from '../components/textBlockCenteredBullet'
import TextBlockRight from '../components/textBlockRight'
import TextBlock2col from '../components/textBlock-2col.js'
import TextBlockImage from '../components/textBlock-image.js'
import BigTitleCaseStudy from '../components/bigTitleCaseStudy.js'
import IntroCaseStudy3 from '../components/introCaseStudy-3.js'
import CustomUI from '../images/Custom-UI.png'
import CardProject from '../components/cardProject'
import FooterCaseStudyBack from '../components/footerCaseStudyBack';

import Layout from "../components/layout"

const slideUp = keyframes`
  0% {
      transform: translateY(-50px)
  }
  100% {
      transform: translateY(0px)
  }
`

const SectionHeroCaseStudy = styled.div`
width: 100%;

  & before {
  content:'';
  height: 0vh;
  width: 100vw;
  background-color: #0065ff;
  position:absolute;
  bottom:0;
  z-index: 100;  
  animation: ${slideUp} 1s forwards cubic-bezier(0.2, 0.8, 0.2, 1.0);
  }
`
const SectionIntroCaseStudy = styled.div`
width: 100%;
`
const SectionTextBlockCentered = styled.div`
width: 100%;
`
const TitlePartContainer = styled.h1`
max-width: 80%;
padding: 3em 0em 0em 0em;
margin: auto;
`
const TitlePart = styled.div`
font-size: 1.6em;
`
 const FooterContainer = styled.div`
  width: 80%;
  margin: 8em auto 4em auto; 
  font-weight: 700;
`
 const LeftContainer = styled.div`
  width: 50%;
  display: inline-block;
`
 const RightContainer = styled.div`
  width: 50%;
  text-align: right;
  display: inline-block;
`
 const PreviousLink = styled.div`
  width: 50%;
  text-align: left;
  display: flex;
  align-items: center;
`
 const NextLink = styled.div`
  width: 50%;
  text-align: right;
  display: inline-block;
  align-items: center;
`


export default class Sevenhugs extends Component {
	render () {
		return (
		<Layout>
		<div>
			<SectionHeroCaseStudy>
				{sevenhugsProductData.heroCaseStudy.map(cell => (
	  		 		 <HeroCaseStudy title={cell.title} 
	  		 		 subtitle={cell.subtitle}
	  		 		 image={cell.image}/>
	 			))}
 			</SectionHeroCaseStudy>
			

			<SectionIntroCaseStudy>
				{sevenhugsProductData.introCaseStudy3.map(cell => (
	  		 		 <IntroCaseStudy3 title={cell.title} 
	  		 		 description={cell.description} 
	  		 		 titleRight1={cell.titleRight1} 
	  		 		 descriptionRight1={cell.descriptionRight1}
	  		 		 titleRight2={cell.titleRight2} 
	  		 		 descriptionRight2={cell.descriptionRight2}
	  		 		 titleRight3={cell.titleRight3} 
	  		 		 descriptionRight3={cell.descriptionRight3}
	  		 		 />
	 			))}
 			</SectionIntroCaseStudy>
			
			{sevenhugsProductData.heroImage.map(cell => (
 				<HeroImage  image={cell.image}></HeroImage>
 			))}
			
		

			{sevenhugsProductData.textBlockImage.map(cell => (
  		 		 <TextBlockImage
  		 		 	 title={cell.title} 
	  		 		 description={cell.description} 
	  		 		 image={cell.image} 
	  		 		 />
 			))}
			

			{sevenhugsProductData.textBlockImage2.map(cell => (
  		 		 <TextBlockImage
  		 		 	 title={cell.title} 
	  		 		 description={cell.description} 
	  		 		 image={cell.image} 
	  		 		 />
 			))}

			
			{sevenhugsProductData.largeImage1.map(cell => (
 				<LargeImage 
 					image={cell.image}
 					caption={cell.caption} 
 				/>
 			))}	
			
			{sevenhugsProductData.bigTitleCaseStudy.map(cell => (
	  		 	 <BigTitleCaseStudy 
	  		 	 	title={cell.title} 
	  		 		subtitle={cell.subtitle}
	  		 		image={cell.image}
	  		 	 />
	 		))}
 			
			
	
			{sevenhugsProductData.textBlock2col.map(cell => (
  		 		 <TextBlock2col
  		 		 	 title1={cell.title1} 
	  		 		 description1={cell.description1} 
	  		 		 description={cell.description} 
	  		 	 />
 			))}

 			{sevenhugsProductData.largeImage2.map(cell => (
 				<LargeImage 
 					image={cell.image}
 					caption={cell.caption} 
 				/>
 			))}	

 			{sevenhugsProductData.largeImage3.map(cell => (
 				<LargeImage 
 					image={cell.image}
 					caption={cell.caption} 
 				/>
 			))}	

 			
			{sevenhugsProductData.textBlock2col2.map(cell => (
  		 		 <TextBlock2col
  		 		 	 title1={cell.title1} 
	  		 		 description1={cell.description1} 
	  		 		 description={cell.description} 
	  		 	 />
 			))}

 			{sevenhugsProductData.largeImage4.map(cell => (
 				<LargeImage 
 					image={cell.image}
 					caption={cell.caption} 
 				/>
 			))}	
		
			{sevenhugsProductData.textBlock2col3.map(cell => (
  		 		 <TextBlock2col
  		 		 	 title1={cell.title1} 
	  		 		 description1={cell.description1} 
	  		 		 description={cell.description} 
	  		 	 />
 			))}

 			{sevenhugsProductData.largeImage5.map(cell => (
 				<LargeImage 
 					image={cell.image}
 					caption={cell.caption} 
 				/>
 			))}	

			{sevenhugsProductData.textBlock2col4.map(cell => (
  		 		 <TextBlock2col
  		 		 	 title1={cell.title1} 
	  		 		 description1={cell.description1} 
	  		 		 description={cell.description} 
	  		 	 />
 			))}
		
			{sevenhugsProductData.largeImage6.map(cell => (
 				<LargeImage 
 					image={cell.image}
 					caption={cell.caption} 
 				/>
 			))}	

			{sevenhugsProductData.textBlock2col5.map(cell => (
  		 		 <TextBlock2col
  		 		 	 title1={cell.title1} 
	  		 		 description1={cell.description1} 
	  		 		 description={cell.description} 
	  		 	 />
 			))}
		

 			{sevenhugsProductData.largeImage8.map(cell => (
 				<LargeImage 
 					image={cell.image}
 					caption={cell.caption} 
 				/>
 			))}	

			{sevenhugsProductData.textBlock2col7.map(cell => (
  		 		 <TextBlock2col
  		 		 	 title1={cell.title1} 
	  		 		 description1={cell.description1} 
	  		 		 description={cell.description} 
	  		 	 />
 			))}

			{sevenhugsProductData.heroImage2.map(cell => (
	  	 		 <HeroImage image={cell.image}/>
	 		))}


			{sevenhugsProductData.textBlock2col8.map(cell => (
  		 		 <TextBlock2col
  		 		 	 title1={cell.title1} 
	  		 		 description1={cell.description1} 
	  		 		 description={cell.description} 
	  		 	 />
 			))}


			{sevenhugsProductData.largeImage9.map(cell => (
 				<LargeImage 
 					image={cell.image}
 					caption={cell.caption} 
 				/>
 			))}	

			{sevenhugsProductData.textBlock2col9.map(cell => (
  		 		 <TextBlock2col
  		 		 	 title1={cell.title1} 
	  		 		 description1={cell.description1} 
	  		 		 description={cell.description} 
	  		 	 />
 			))}

			{sevenhugsProductData.largeImage10.map(cell => (
 				<LargeImage 
 					image={cell.image}
 					caption={cell.caption} 
 				/>
 			))}	

			{sevenhugsProductData.textBlock2col10.map(cell => (
  		 		 <TextBlock2col
  		 		 	 title1={cell.title1} 
	  		 		 description1={cell.description1} 
	  		 		 description={cell.description} 
	  		 	 />
 			))}

			{sevenhugsProductData.largeImage11.map(cell => (
 				<LargeImage 
 					image={cell.image}
 					caption={cell.caption} 
 				/>
 			))}	

			<div>
			    <FooterContainer>
			      <LeftContainer>
			        <PreviousLink>
			           <Link to="/work">← Back to Work</Link>
			        </PreviousLink>
			      </LeftContainer>
			      <RightContainer>
			        <NextLink>
			          <Link to="/sevenhugs-brand">Next →</Link>
			        </NextLink>
			      </RightContainer>
			    </FooterContainer>
  			</div>

		</div>
		</Layout>
		)
	}
}